<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-12">
      <breadcrumbs
        :path="[
          { name: $t('add-brand-view.clients'), url: { name: 'Clients' } },
          { name: client ? client.name : '', url: clientRoute },
          { name: $t('add-brand-view.add-brand') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>

      <brand-form
        id="brandForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="brandForm"
            class="px-5 md:w-48 mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
          <btn
            :is-loading="!redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="brandForm"
            class="w-48"
            theme="secondary"
            @click="redirectAfterSubmission = false"
          >
            {{ $t('brand-form.save-and-add-another') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push(clientRoute)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import BrandForm from '@/views/Dashboard/Brands/Forms/BrandForm.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'AddBrand',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    BrandForm,
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('client/fetchClientDetails', to.params['id']);
    next();
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: {
    ...mapState('client', {
      client: 'details',
    }),
    clientRoute() {
      if (this.client)
        return { name: 'ClientDetails', params: { id: this.client.id } };
      else return {};
    },
  },
  methods: {
    ...mapActions('brand', ['createBrand']),
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.createBrand({ ...formData, clientId: this.client.id });

        this.$toast.success(this.$t('add-brand-view.added-successfully'));
        if (this.redirectAfterSubmission) this.$router.push(this.clientRoute);

        this.submissionEndSuccessfully = true;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
