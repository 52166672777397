var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('image-uploader',{staticClass:"mt-6 mb-1",attrs:{"is-error":errors[0],"padding":"px-4","required":""},model:{value:(_vm.logoUrl),callback:function ($$v) {_vm.logoUrl=$$v},expression:"logoUrl"}})]}}],null,true)}),_c('form',{ref:"form",staticClass:"form py-1",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('label',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('brand-form.name'),"placeholder":_vm.$t('brand-form.name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('label',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('brand-form.short-name'),"placeholder":_vm.$t('brand-form.short-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.shortName),callback:function ($$v) {_vm.shortName=$$v},expression:"shortName"}})]}}],null,true)})],1),(_vm.content)?_c('div',[_c('switch-input',{staticClass:"my-5",attrs:{"label":_vm.$t('brand-form.setting-budgets'),"label-position":_vm.LabelPosition.RIGHT,"size":_vm.SwitchSize.MEDIUM},model:{value:(_vm.isBudgetEnabled),callback:function ($$v) {_vm.isBudgetEnabled=$$v},expression:"isBudgetEnabled"}}),(_vm.isBudgetEnabled)?_c('select-input',{staticClass:"my-2",attrs:{"options":_vm.structureLevelsOptions,"option-label-name":"name","option-value-name":"id","placeholder":"Places","no-margin":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [(selection && selection.level > 0)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('brand-form.level'))+" "+_vm._s(selection.level))]),_c('span',{staticClass:"text-gray ml-2"},[_vm._v("("+_vm._s(selection.name)+")")])]):_c('span',[_vm._v(_vm._s(selection.name))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [(option.level > 0)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('brand-form.level'))+" "+_vm._s(option.level))]),_c('span',{staticClass:"text-gray ml-2"},[_vm._v("("+_vm._s(option.name)+")")])]):_c('div',[_c('span',[_vm._v(_vm._s(option.name))])])]}}],null,true),model:{value:(_vm.budgetSettingLevel),callback:function ($$v) {_vm.budgetSettingLevel=$$v},expression:"budgetSettingLevel"}}):_vm._e(),_c('info-bar',{staticClass:"border-2 border-yellow rounded-md p-2 px-4",attrs:{"show":_vm.showWarningBar,"no-spacing":""}},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('icon',{staticClass:"text-gray-darker",attrs:{"icon":_vm.mdiAlertOctagonOutline}}),_c('span',{staticClass:"text-xs text-left"},[_vm._v(" "+_vm._s(_vm.$t('brand-form.level-change-warning'))+" ")])],1)])],1):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }