<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <ValidationProvider v-slot="{ errors }" rules="required">
      <image-uploader
        v-model="logoUrl"
        :is-error="errors[0]"
        class="mt-6 mb-1"
        padding="px-4"
        required
      />
    </ValidationProvider>
    <form
      :id="id"
      ref="form"
      class="form py-1"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <label>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="name"
            :label="$t('brand-form.name')"
            :placeholder="$t('brand-form.name-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
          ></text-input>
        </ValidationProvider>
      </label>
      <label>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <text-input
            v-model="shortName"
            :label="$t('brand-form.short-name')"
            :placeholder="$t('brand-form.short-name-placeholder')"
            :error-message="errors[0]"
            :disabled="isLoading"
            required
          ></text-input>
        </ValidationProvider>
      </label>
      <div v-if="content">
        <switch-input
          v-model="isBudgetEnabled"
          class="my-5"
          :label="$t('brand-form.setting-budgets')"
          :label-position="LabelPosition.RIGHT"
          :size="SwitchSize.MEDIUM"
        />
        <select-input
          v-if="isBudgetEnabled"
          v-model="budgetSettingLevel"
          :options="structureLevelsOptions"
          option-label-name="name"
          option-value-name="id"
          placeholder="Places"
          class="my-2"
          no-margin
        >
          <template #selection="{ selection }">
            <span v-if="selection && selection.level > 0">
              <span>{{ $t('brand-form.level') }} {{ selection.level }}</span>
              <span class="text-gray ml-2">({{ selection.name }})</span>
            </span>
            <span v-else>{{ selection.name }}</span>
          </template>
          <template #option="{ option }">
            <div v-if="option.level > 0">
              <span>{{ $t('brand-form.level') }} {{ option.level }}</span>
              <span class="text-gray ml-2">({{ option.name }})</span>
            </div>
            <div v-else>
              <span>{{ option.name }}</span>
            </div>
          </template>
        </select-input>
        <info-bar
          class="border-2 border-yellow rounded-md p-2 px-4"
          :show="showWarningBar"
          no-spacing
        >
          <div class="flex gap-2 items-center">
            <icon :icon="mdiAlertOctagonOutline" class="text-gray-darker" />
            <span class="text-xs text-left">
              {{ $t('brand-form.level-change-warning') }}
            </span>
          </div>
        </info-bar>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  LabelPosition,
  SelectInput,
  SwitchInput,
  SwitchSize,
  TextInput,
} from '@/components/Inputs';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { getStructuresLevels } from '@/api/requests/structures';
import { InfoBar } from '@/components/InfoBar';
import { mdiAlertOctagonOutline } from '@mdi/js';
import { Icon, IconSize } from '@/components/Icon';
import { onMounted, ref } from '@vue/composition-api';

export default {
  components: {
    Icon,
    SelectInput,
    SwitchInput,
    TextInput,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    InfoBar,
  },
  computed: {
    showWarningBar() {
      const budgetSettingChanged =
        this.budgetSettingLevel &&
        this.budgetSettingLevel?.level !== this.content.budgetSettingLevel;

      const budgetToggled =
        this.isBudgetEnabled !== this.content.isBudgetEnabled &&
        this.content.isBudgetEnabled === true;

      return budgetSettingChanged || budgetToggled;
    },
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: null,
    },
    clear: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      name: '',
      shortName: '',
      logoUrl: null,
      isBudgetEnabled: false,
      budgetSettingLevel: null,
    };
  },
  watch: {
    clear(val) {
      if (val) this.clearForm();
    },
    content: {
      immediate: true,
      async handler(newContent) {
        if (newContent !== null) {
          const {
            name,
            shortName,
            logoUrl,
            isBudgetEnabled,
            budgetSettingLevel,
          } = newContent;

          this.name = name;
          this.shortName = shortName;
          this.logoUrl = logoUrl;
          this.isBudgetEnabled = isBudgetEnabled;

          const { results } = await getStructuresLevels({
            brandId: this.content.id,
          });

          const budgetSettingLevelOption = results.find(
            (structureLevel) => structureLevel?.level === budgetSettingLevel,
          );

          this.budgetSettingLevel = budgetSettingLevelOption;
        }
      },
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  methods: {
    pushFormData() {
      this.$emit('on-submit', {
        name: this.name,
        shortName: this.shortName,
        logoUrl: this.logoUrl,
        ...(this.content && {
          isBudgetEnabled: this.isBudgetEnabled,
          budgetSettingLevel: this.budgetSettingLevel?.level ?? null,
        }),
      });
    },
    clearForm() {
      this.name = '';
      this.shortName = '';
      this.logoUrl = null;
      this.isBudgetEnabled = false;
      this.budgetSettingLevel = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
  setup(props) {
    const structureLevelsOptions = ref([]);

    const getStructureLevelOptions = async () => {
      const { results } = await getStructuresLevels({
        brandId: props.content.id,
      });

      return [
        ...results.filter((structure) => structure?.level !== 0),
        {
          id: null,
          level: null,
          name: 'Places',
        },
      ];
    };

    const extendOptionNameWithStructurePath = (options) => {
      let previousName = options[0].name;

      for (let i = 1; i < options.length; i++) {
        if (options[i].level) {
          options[i].name = `${previousName} > ${options[i].name}`;
        }

        previousName = options[i].name;
      }

      return options;
    };

    const getFormattedStructureLevelsOptions = async () => {
      const options = await getStructureLevelOptions();
      structureLevelsOptions.value = extendOptionNameWithStructurePath(options);
    };

    onMounted(async () => {
      await getFormattedStructureLevelsOptions();
    });

    return {
      LabelPosition,
      SwitchSize,
      mdiAlertOctagonOutline,
      IconSize,
      structureLevelsOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
label {
  @apply mt-4 block;
  span.required {
    &::after {
      content: '*';
      @apply text-red;
    }
  }
  span {
    @apply my-1 block;
  }
}
.toggle-label {
  @apply mt-6 flex items-center;
}
</style>
